 
import { Vue, Component, Watch } from "vue-property-decorator";
import { ParametroFinanceiroService } from "@/core/services/financeiro";
import { ParametroFinanceiro, ValoresAdicionais } from "@/core/models/financeiro";
import { mixins } from 'vue-class-component';
import ListPage from '@/components/shared/ListPage';
 
@Component
export default class ListaParametrizar extends mixins(Vue,ListPage) {
  public service = new ParametroFinanceiroService();
  public item = new ParametroFinanceiro();
   
  titulo: string = 'Parametrização das Empresas';
  subTitulo: string = 'listas de parâmetros das empresas';

  dialogCadastro: boolean = false;
  loading: boolean = false; 
  totalLista: number = 0;

  headers: any[] = [
    { text: "", value: "actions", sortable: false },
    { text: "Empresa", value: "empresa.nomeFantasia" },
  ];

  options: any = {
    itemsPerPage: 15
  };
 
  
  breadCrumbLinks: any[] = [
    { text: 'Home', href: '/financeiro/painel/kpi',},
    { text: 'Lista de Parametrização', disabled: true, href: '/financeiro/painel/despesas'}
  ]
 
 @Watch("options", { deep: true })
  Atualizar() { 
    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
    this.loading = true;

    this.service.Listar(page,itemsPerPage,sortBy,sortDesc,search, columns, undefined, 'Empresa/nomeFantasia', 'Id', '').then(
      res => {
        this.lista = res.data.items;
        this.totalLista = res.data.count;
      },
    (err) => { 
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      })
    .finally(() => (this.loading = false));
  }

  Editar(item: ParametroFinanceiro) {
    this.service.ObterPorId(item.id, 'Gatways.ContasCorrentesSplit, Cobrancas, Empresa.Endereco, Empresa.SelfieResponsavel, Empresa.VersoDocumentoResponsavel, Empresa.FotoDocumentoResponsavel, Empresa.ContratoSocialLtda').then( 
      res => {
        this.item = new ParametroFinanceiro(res.data);
        this.dialogCadastro = true;
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      }); 
  }

  Novo() {
    this.item = new ParametroFinanceiro(); 
    this.dialogCadastro = true;
  } 

  Excluir(item: ParametroFinanceiro) {
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja cancelar o registro atual?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.service.Excluir(item.id).then(
          (res) => {
            if (res.status == 200) {
              this.Atualizar();
              return res;
            }
          },
         (err) => {
            if (!err.response) {
              this.$swal("Aviso", "Não foi possível acessar a API", "error");
            } else if (err.response.status == 403) {
              this.$swal("Aviso", err.response.data.message, "warning" );
            } else {
              this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
            }
          }); 
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result: any) => {
      if (result.value) {
        this.$swal(
          "Aviso",
          result.value.data,
          result.value.status == 200 ? "success" : "warning"
        ); 
      }
    });
  }
  mounted(){
    //this.Atualizar();
  } 
}
